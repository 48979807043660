.App {
  display: grid;
  height: 100vh;
  grid-template-rows: auto 1fr auto;
}

header {
  padding: 2rem;
  font-size: 1.5rem;
  font-family: Assistant, serif;
  background: hsl(190deg 30% 96.08%);
}

main {
  padding: 2rem;
  font-size: 1.5rem;
  background: #ffffff;
  font-family: lato, serif;
}

footer {
  padding: 2rem;
  font-size: 1rem;
  text-align: center;
  font-family: PixelifySans, serif;
  background: hsl(190deg 30% 96.08%);;
}

#divider {
  width: 100%;
  height: 1px;
  background-color: #2c2a2e;
}
#title_navigation {
  font-size: 1.8rem;
  font-family: Assistant, serif;
}
.navigation_link {
  text-decoration: underline;
}

li {
  cursor: pointer;
  padding: 0.5rem;
  font-size: 1.5rem;
  font-family: lato, serif;
}

.link {
  cursor: pointer;
  padding: 0.5rem;
  font-size: 1.5rem;
  font-family: lato, serif;
}