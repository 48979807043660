body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'PixelifySans';
  src: local('PixelifySans'), url(./fonts/pixelifysans/PixelifySans[wght].ttf) format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Assistant';
  src: local('Assistant'), url(./fonts/assistant/Assistant[wght].ttf) format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Caveat';
  src: local('Caveat'), url(./fonts/caveat/Caveat[wght].ttf) format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Lato';
  src: local('Lato'), url(./fonts/lato/Lato-Regular.ttf) format('truetype');
  font-display: swap;
}